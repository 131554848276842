import React, { useEffect, useState, useRef } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { IoIosExit } from "react-icons/io";

import Profile from "../../assets/images/user.png";
import Logo from "../../assets/images/svg/logo.svg";
import axios from "axios";

const Header = () => {
  const [dropDown, setDropDown] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();
  useEffect(() => {
    // hh
    const handleClickDropdown = (e) => {
      if (dropDown && ref.current && !ref.current.contains(e.target)) {
        setDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleClickDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickDropdown);
    };
  }, [dropDown]);

  const handleLogout = () => {
    window.localStorage.clear();
    window.localStorage.clear();
    localStorage.removeItem("username");
    navigate("/");

    window.location.href = window.location.origin + "/";
  };

  useEffect(() => {
    if (localStorage.getItem("username") === null || "") {
      navigate("/");
    } else {
      navigate("/allinvoice");
    }
  }, []);

  const settings = () => {
    navigate("/settings");
  };
  const userID = localStorage.getItem("userID");
  const [companyName, setCompanyName] = useState("");

  const getRegdata = () => {
    let da = "";
    let con = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/getReg/${userID}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
      },
      data: da,
    };
    axios
      .request(con)
      .then((res) => {
        res.data.data.map((value) => {
          setCompanyName(value.companyName);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRegdata();
  }, []);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className="navbar"
      >
        <Container>
          <Navbar.Brand>
            {/* navigate("/allinvoice"); */}
            <Link to="/allinvoice">
              <img src={Logo} alt="logo" id="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className="navmenu">
              <Nav.Link>
                {/* <Link to="/create-bill">Create Bill</Link>
                <Link to="/export-invoice">Export Invoice</Link> */}
                <Link to="/allinvoice">Shipping Bill</Link>
                <Link to="/shipping-bill-value">EGM & BRC</Link>
                {/* <Link>GSTR</Link>
                <Link>BRC</Link>
                <Link>GST Refund</Link> */}
                <Link to="/settings">Settings</Link>
                <Link id="logout" onClick={handleLogout}>
                  <IoIosExit /> Logout
                </Link>
                {/* <Link to="">Completed</Link> */}
              </Nav.Link>

              <Nav.Link>
                <Link>
                  <img src={Profile} id="profile-pic" alt="Profile Pic" />

                  {dropDown && (
                    <div className="flex flex-col dropDownProfile">
                      <div className="box"></div>
                      <ul className="flex flex-col gap-4">
                        <li>
                          {/* <AiTwotoneSetting /> */}
                          <Link id="red" to="/settings">
                            Settings
                          </Link>
                        </li>

                        <li id="red" onClick={handleLogout}>
                          <IoIosExit id="logout" />
                          &nbsp; Logout
                        </li>
                      </ul>
                    </div>
                  )}
                </Link>
              </Nav.Link>
              <Nav.Link>
                <p>{companyName}</p>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
