import React, { useEffect, useState } from "react";
import axios from "axios";

const DataTable = () => {
  const [data, setData] = useState([]);
  const userID = localStorage.getItem("userID");
  useEffect(() => {
    const fetchData = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://easy.multigrad.in/api//shipping-bill-values/${userID}`,
        headers: {
          token:
            "20b36f0f18fe59b51041f1c8e049007242fb40e53e25d093f5b652f9039f527b",
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data.data));
          setData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="billing_history">
        <div className="home_inner_first">
          <div className="home_left">
            <h3>EGM & BRC</h3>
            <p></p>
          </div>
        </div>
      </div>
      <div className="invoice_table">
        <table responsive>
          <thead>
            <tr>
              <th>Buyer Name</th>
              <th>Port Code</th>
              <th>Shipping Bill</th>
              <th>Invoice Number & Date</th>
              <th>Invoice Value</th>
              <th>EGM Number</th>
              <th>EGM Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  {" "}
                  <p>{item.buyer_name} </p>
                </td>
                <td>
                  <p> {item.portcode} </p>
                </td>
                <td>
                  <p>{item.sb_no} </p>
                </td>
                <td>
                  <p>{item.invoice_no_dt}</p>
                </td>
                <td>
                  <p>{item.invoice_value}</p>
                </td>
                <td>
                  <p>{item.egmNumber} </p>
                </td>
                <td>
                  <p>{item.egmDate} </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
