import React, { useEffect, useState } from "react";
import "../../assets/css/create.css";
import { Button, Form, Card } from "react-bootstrap";
import axios from "axios";
import Export from "../../assets/images/export.png";
import Bill from "../../assets/images/bill.png";

const CreateBill = (prop) => {
  const userID = localStorage.getItem("userID");
  const [signature, setSignature] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/signature/${userID}`,
          {
            headers: {
              token: process.env.REACT_APP_TOKEN,
            },
          }
        );

        setSignature(response.data.data.signature);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const initialItem = Array.from({ length: 30 }, () => ({
    descriptionOfGoods: "",
    hsnOrSac: "",
    kgs: "",
    quantity: "",
    unit: "",
    rate: "",
    totalAmount: "",
  }));

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const handlePrint = () => {
    console.log("Printing...");
    window.print();
  };

  const [formData, setFormData] = useState({
    userId: "",
    address: "",
    invoiceNumber: "",
    district: "",
    state: "",
    contactNumber: "",
    emailAddress: "",
    panNumber: "",
    gstNumber: "",
    createDate: "",
    grossWeight: null,
    numberOfPackages: null,
    netWeight: null,
    ieCode: "",
    adCode: "",
    billingTo: "",
    tpnNumber: "",
    billingAddress: "",
    billingContact: "",
    bankName: "",
    bankNumber: "",
    accountName: "",
    branchName: "",
    ifscCode: "",
    items: [initialItem],
  });

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (index !== undefined) {
      const updatedItems = [...formData.items];
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      };

      // Calculate the totalAmount
      const updatedItem = updatedItems[index];
      if (name === "quantity" || name === "rate") {
        const quantity = parseFloat(updatedItem.quantity);
        const rate = parseFloat(updatedItem.rate);
        updatedItem.totalAmount = (quantity * rate).toFixed(2);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        items: updatedItems,
        subTotal: calculateSubTotal(updatedItems),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const calculateSubTotal = (items) => {
    const totalAmounts = items.map((item) => parseFloat(item.totalAmount));
    const subtotal = totalAmounts.reduce(
      (total, amount) => total + (isNaN(amount) ? 0 : amount),
      0
    );
    return subtotal.toFixed(2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    let items = formData.items.map((val) => {
      return {
        snNumber: val.snNumber,
        descriptionOfGoods: val.descriptionOfGoods,
        hsnOrSac: val.hsnOrSac,
        kgs: val.kgs,
        quantity: val.quantity,
        unit: val.unit,
        rate: val.rate,
        totalAmount: val.totalAmount,
      };
    });
    let data = JSON.stringify({
      userId: localStorage.getItem("userID"),
      address: formData.address,
      invoiceNumber: formData.invoiceNumber,
      district: formData.district,
      state: formData.state,
      contactNumber: formData.contactNumber,
      emailAddress: formData.emailAddress,
      panNumber: formData.panNumber,
      gstNumber: formData.gstNumber,
      createDate: formData.createDate,
      vehicleNumber: formData.vehicleNumber,
      driverNumber: formData.driverNumber,
      numberOfPackages: formData.numberOfPackages,
      grossWeight: formData.grossWeight,
      netWeight: formData.netWeight,
      ieCode: "IE123456",
      lutNumber: formData.lutNumber,
      adCode: formData.adCode,
      billingTo: formData.billingTo,
      tpnNumber: formData.tpnNumber,
      billingAddress: formData.billingAddress,
      billingContact: formData.billingContact,
      bankName: formData.bankName,
      bankNumber: formData.bankNumber,
      accountName: formData.accountName,
      branchName: formData.branchName,
      ifscCode: formData.ifscCode,
      items: items,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/bill`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response);
        console.log("Enter Api Section");
      })
      .catch((error) => {
        console.log(error);
      });
    if (formData.invoiceNumber !== "" && formData.bankName !== "") {
      window.print();
    }
  };

  const [companyName, setCompanyName] = useState();
  const getRegData = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/getReg/${userID}`, {
        headers: {
          token: process.env.REACT_APP_TOKEN,
        },
      })
      .then((response) => {
        response.data.data.map((value) => {
          console.log(value);
          setCompanyName(value.companyName);
          setFormData({
            email: value.email,
            panNumber: value.panCard,
            address: value.companyAddress,
            district: value.companyDistrict,
            state: value.companyState,
            contactNumber: value.companyNumber,
            emailAddress: value.email,
            gstNumber: value.gstNumber,
            ieCode: "IE123456",
            adCode: value.adCode,
            bankName: value.bankName,
            bankNumber: value.bankNumber,
            accountName: value.bankAccountee,
            branchName: value.branchName,
            ifscCode: value.bankIfsc,
            items: initialItem,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAdd = () => {
    if (formData.items.length < 20) {
      setFormData((prevState) => ({
        ...prevState,
        items: [...prevState.items, {}],
      }));
    }
  };

  const handleRemove = (index) => {
    setFormData((prevState) => ({
      items: prevState.items.filter((item, i) => i !== index),
    }));
  };

  useEffect(() => {
    prop.setShow(true);
    getRegData();
  }, []);
  return (
    <>
      <>
        {/* <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
  <title>file_1688030156963</title>
  <meta name="author" content="Ajay Gupta" />
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        " * {margin:0; padding:0; text-indent:0; }\n .s1 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }\n .s2 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 10pt; }\n .s3 { color: black; font-family:Cambria, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 20pt; }\n .s4 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 9pt; }\n .s5 { color: black; font-family:Cambria, serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 12pt; }\n .s6 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 12pt; }\n .s7 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 10pt; }\n .s8 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }\n .s9 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }\n p { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; margin:0pt; }\n table, tbody {vertical-align: top; overflow: visible; }\n"
    }}
  /> */}
        <div className="printDivArea">
          <p
            className="s1"
            style={{
              paddingLeft: "158pt",
              marginLeft: "14%",
            }}
          >
            Supply meant for Export on N.A.
          </p>
          <Form onSubmit={handleSubmit}>
            <table
              style={{ borderCollapse: "collapse", marginLeft: "5.65pt" }}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "111pt",
                        paddingRight: "126pt",
                        textIndent: "0pt",
                        textAlign: "center",
                        fontFamily: "bl-regular",
                      }}
                    >
                      EXPORT INVOICE
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "24pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s3"
                      style={{
                        paddingLeft: "128pt",
                        paddingRight: "126pt",
                        textIndent: "0pt",
                        lineHeight: "23pt",
                        textAlign: "center",
                        fontWeight: 900,
                        fontSize: "40px",
                        fontFamily: "bl-bold",
                        padding: "10px",
                      }}
                    >
                      {companyName}
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "372pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={4}
                    rowSpan={7}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "154pt",
                        textIndent: "0pt",
                        lineHeight: "119%",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      Address:{" "}
                      <input
                        className="input_c_bill"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      District:{" "}
                      <input
                        className="input_c_bill"
                        name="district"
                        value={formData.district}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      State:{" "}
                      <input
                        className="input_c_bill"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      Contact No:{" "}
                      <input
                        className="input_c_bill"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      E-mail:{" "}
                      <input
                        className="input_c_bill"
                        value={formData.emailAddress}
                        name="emailAddress"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      PAN:{" "}
                      <input
                        className="input_c_bill"
                        value={formData.panNumber}
                        onChange={handleChange}
                        name="panNumber"
                        autoComplete="off"
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      GST:{" "}
                      <input
                        className="input_c_bill"
                        value={formData.gstNumber}
                        onChange={handleChange}
                        name="gstNumber"
                        autoComplete="off"
                      />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Invoice No:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "25pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="invoiceNumber"
                        value={formData.invoiceNumber}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "17pt",
                        textIndent: "0pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      Date:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="createDate"
                        value={formData.createDate}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Vehicle No:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "33pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="vehicleNumber"
                        value={formData.vehicleNumber}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "16pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Driver:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "22pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="driverNumber"
                        value={formData.driverNumber}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      No. of Package:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "33pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="numberOfPackages"
                        value={formData.numberOfPackages}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "3pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Gross Weight:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="grossWeight"
                        value={formData.grossWeight}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "7pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Net Weight:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="netWeight"
                        value={formData.netWeight}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "15pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s5"
                      style={{
                        paddingLeft: "128pt",
                        paddingRight: "126pt",
                        textIndent: "0pt",
                        lineHeight: "13pt",
                        textAlign: "center",
                      }}
                    >
                      I.E.CODE: AHZPY7946H AD CODE: 0270945-1010286
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "15pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s6"
                      style={{
                        paddingLeft: "2pt",
                        textIndent: "0pt",
                        lineHeight: "14pt",
                        textAlign: "left",
                      }}
                    >
                      To:
                      <input
                        className="input_c_bill"
                        name="billingTo"
                        value={formData.billingTo}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "280pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      lineHeight: "1rem",
                      borderRightColor: "#808080",
                      marginTop: "10px",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      TPN:{" "}
                      <span className="s7">
                        <input
                          className="input_c_bill"
                          name="tpnNumber"
                          value={formData.tpnNumber}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "92pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "29pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Contact:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "182pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={4}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <input
                        className="input_c_bill"
                        name="billingContact"
                        value={formData.billingContact}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "18pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Address:{" "}
                      <span className="s8">
                        <input
                          className="input_c_bill"
                          name="billingAddress"
                          value={formData.billingAddress}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "21pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "3pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      S.n.
                    </p>
                  </td>
                  <td
                    style={{
                      width: "259pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "86pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Description of Goods
                    </p>
                  </td>
                  <td
                    style={{
                      width: "49pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      HSN/SAC
                    </p>
                  </td>
                  <td
                    style={{
                      width: "43pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Packing
                    </p>
                  </td>
                  <td
                    style={{
                      width: "33pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Qnty
                    </p>
                  </td>
                  <td
                    style={{
                      width: "27pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Unit
                    </p>
                  </td>
                  <td
                    style={{
                      width: "50pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "5pt",
                        paddingRight: "3pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "center",
                      }}
                    >
                      Rate
                    </p>
                  </td>
                  <td
                    style={{
                      width: "72pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "19pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Amount
                    </p>
                  </td>
                </tr>

                {formData.items.slice(0, 30).map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        width: "21pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "0pt",
                        borderTopColor: "#808080",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1pt",
                        borderLeftColor: "#808080",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0pt",
                        borderBottomColor: "#808080",
                        borderRightStyle: "solid",
                        borderRightWidth: "1pt",
                        borderRightColor: "#808080",
                      }}
                    >
                      <p
                        className="s8"
                        style={{
                          paddingLeft: "8pt",
                          textIndent: "0pt",
                          textAlign: "left",
                          fontSize: "18px",
                        }}
                      >
                        {index + 1}
                      </p>
                    </td>
                    <td
                      style={{
                        width: "259pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "0pt",
                        borderTopColor: "#808080",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1pt",
                        borderLeftColor: "#808080",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0pt",
                        borderBottomColor: "#808080",
                        borderRightStyle: "solid",
                        borderRightWidth: "1pt",
                        borderRightColor: "#808080",
                      }}
                    >
                      <p
                        className="s8"
                        style={{
                          paddingLeft: "1pt",
                          paddingRight: "194pt",
                          textIndent: "0pt",
                          lineHeight: "119%",
                          textAlign: "left",
                          fontSize: "18px",
                        }}
                      >
                        <input
                          className="input_c_bill"
                          name="descriptionOfGoods"
                          value={item.descriptionOfGoods}
                          onChange={(e) => handleChange(e, index)}
                          autoComplete="off"
                        />
                      </p>
                    </td>
                    <td
                      style={{
                        width: "49pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "0pt",
                        borderTopColor: "#808080",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1pt",
                        borderLeftColor: "#808080",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0pt",
                        borderBottomColor: "#808080",
                        borderRightStyle: "solid",
                        borderRightWidth: "1pt",
                        borderRightColor: "#808080",
                      }}
                    >
                      <p
                        className="s8"
                        style={{
                          paddingLeft: "5pt",
                          textIndent: "0pt",
                          textAlign: "left",
                          fontSize: "18px",
                        }}
                      >
                        <input
                          className="item_c_bill"
                          name="hsnOrSac"
                          value={item.hsnOrSac}
                          autoComplete="off"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </p>
                    </td>
                    <td
                      style={{
                        width: "43pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "0pt",
                        borderTopColor: "#808080",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1pt",
                        borderLeftColor: "#808080",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0pt",
                        borderBottomColor: "#808080",
                        borderRightStyle: "solid",
                        borderRightWidth: "1pt",
                        borderRightColor: "#808080",
                      }}
                    >
                      <p
                        className="s8"
                        style={{
                          paddingLeft: "7pt",
                          textIndent: "0pt",
                          textAlign: "left",
                          fontSize: "18px",
                        }}
                      >
                        <input
                          className="item_c_bill"
                          name="unit"
                          value={item.unit}
                          autoComplete="off"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </p>
                    </td>
                    <td
                      style={{
                        width: "33pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "0pt",
                        borderTopColor: "red",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1pt",
                        borderLeftColor: "#808080",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0pt",
                        borderBottomColor: "#808080",
                        borderRightStyle: "solid",
                        borderRightWidth: "1pt",
                        borderRightColor: "#808080",
                      }}
                    >
                      <p
                        className="s8"
                        style={{
                          paddingLeft: "9pt",
                          textIndent: "0pt",
                          textAlign: "left",
                          fontSize: "18px",
                        }}
                      >
                        <input
                          className="item_c_bill"
                          name="quantity"
                          value={item.quantity}
                          autoComplete="off"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </p>
                    </td>
                    <td
                      style={{
                        width: "27pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "0pt",
                        borderTopColor: "#808080",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1pt",
                        borderLeftColor: "#808080",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0pt",
                        borderBottomColor: "#808080",
                        borderRightStyle: "solid",
                        borderRightWidth: "1pt",
                        borderRightColor: "#808080",
                      }}
                    >
                      <p
                        className="s8"
                        style={{
                          paddingLeft: "6pt",
                          paddingRight: "3pt",
                          textIndent: "0pt",
                          lineHeight: "119%",
                          textAlign: "justify",
                          fontSize: "18px",
                        }}
                      >
                        <input
                          className="item_c_bill"
                          name="kgs"
                          value={item.kgs}
                          autoComplete="off"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </p>
                    </td>
                    <td
                      style={{
                        width: "50pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "0pt",
                        borderTopColor: "#808080",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1pt",
                        borderLeftColor: "#808080",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0pt",
                        borderBottomColor: "#808080",
                        borderRightStyle: "solid",
                        borderRightWidth: "1pt",
                        borderRightColor: "#808080",
                      }}
                    >
                      <p
                        className="s8"
                        style={{
                          textIndent: "0pt",
                          textAlign: "right",
                          fontSize: "18px",
                        }}
                      >
                        <input
                          className="item_c_bill"
                          name="rate"
                          value={item.rate}
                          autoComplete="off"
                          onChange={(e) => handleChange(e, index)}
                          style={{
                            outline: "none",
                            border: "none",
                            textAlign: "left",
                            borderRadius: 0,
                            marginRight: "0px",
                            float: "right",
                            backgroundColor: "white",
                          }}
                        />
                      </p>
                    </td>
                    <td
                      style={{
                        width: "72pt",
                        borderTopStyle: "solid",
                        borderTopWidth: "0pt",
                        borderTopColor: "#808080",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1pt",
                        borderLeftColor: "#808080",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0pt",
                        borderBottomColor: "#808080",
                        borderRightStyle: "solid",
                        borderRightWidth: "1pt",
                        borderRightColor: "#808080",
                      }}
                    >
                      <p
                        className="s8"
                        style={{
                          textIndent: "0pt",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        <input
                          value={item.totalAmount}
                          autoComplete="off"
                          style={{
                            outline: "none",
                            border: "none",
                            textAlign: "right",
                            borderRadius: 0,
                            marginRight: "0px",
                            float: "right",
                            fontSize: "18px",
                            backgroundColor: "white",
                          }}
                          // onChange={(e) => handleChange(e, index)}
                          name="totalAmount"
                          disabled
                        />
                      </p>
                    </td>
                    {/* {
                      index !== 0 &&

                      <button variant="primary" size="sm"
                        className='hide'
                        onClick={(e) => handleRemove(e, index)}
                      >Remove</button>} */}
                  </tr>
                ))}
                {/* {showAdditionalFields &&
                  formData.items.slice(20).map((item, index) => (
                    <tr className="row17" key={index + 20}>

                    </tr>
                  ))} */}

                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "280pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{ textIndent: "0pt", textAlign: "right" }}
                    >
                      Sub Total
                    </p>
                  </td>
                  <td
                    style={{
                      width: "49pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "43pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "33pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s8"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      {/* 2149 */}
                    </p>
                  </td>
                  <td
                    style={{
                      width: "27pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s8"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      {/* KGS */}
                    </p>
                  </td>
                  <td
                    style={{
                      width: "50pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "72pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s8"
                      style={{
                        paddingLeft: "22pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="subTotal"
                        id="subTotal"
                        autoComplete="off"
                        value={formData.subTotal}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "4pt" }}>
                  <td
                    style={{
                      width: "432pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={6}
                    rowSpan={3}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: "4pt",
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Rupees:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "50pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s9"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "5pt",
                        paddingRight: "3pt",
                        textIndent: "0pt",
                        textAlign: "center",
                      }}
                    >
                      Round Off
                    </p>
                  </td>
                  <td
                    style={{
                      width: "72pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "50pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "5pt",
                        paddingRight: "3pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "center",
                      }}
                    >
                      G.TOTAL
                    </p>
                  </td>
                  <td
                    style={{
                      width: "72pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "12pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="subTotal"
                        id="subTotal"
                        autoComplete="off"
                        value={formData.subTotal}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "72pt" }}>
                  <td
                    style={{
                      width: "280pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      COMPANY BANK DETAILS:
                    </p>
                    <p
                      className="s8"
                      style={{
                        paddingTop: "2pt",
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      BANK NAME:
                      <input
                        className="input_c_bill"
                        name="bankName"
                        autoComplete="off"
                        value={formData.bankName}
                        onChange={handleChange}
                      />
                    </p>
                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      A/C Name
                      <input
                        className="input_c_bill"
                        name="accountName"
                        autoComplete="off"
                        value={formData.accountName}
                        onChange={handleChange}
                      />
                    </p>

                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      BRANCH NAME
                      <input
                        className="input_c_bill"
                        name="branchName"
                        value={formData.branchName}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </p>

                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      ACCOUNT NO
                      <input
                        className="input_c_bill"
                        name="bankNumber"
                        autoComplete="off"
                        value={formData.bankNumber}
                        onChange={handleChange}
                      />
                    </p>

                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      IFSC CODE
                      <input
                        className="input_c_bill"
                        autoComplete="off"
                        value={formData.ifscCode}
                        onChange={handleChange}
                        name="ifscCode"
                      />
                    </p>

                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      AD CODE
                      <input
                        className="input_c_bill"
                        name="adCode"
                        autoComplete="off"
                        value={formData.adCode}
                        onChange={handleChange}
                      />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "152pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={6}
                  >
                    <p
                      style={{
                        paddingLeft: "2pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <span></span>
                    </p>
                    <table border={0} cellSpacing={0} cellPadding={0}>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src={Export}
                              width="120px"
                              height="120px"
                              style={{
                                float: "right",
                                marginLeft: 20,
                                marginTop: 30,
                                padding: "0px",
                              }}
                            />
                          </td>
                          <td>
                            <img
                              src={Bill}
                              width="120px"
                              height="120px"
                              style={{
                                float: "right",
                                marginLeft: 0,
                                padding: "0px",
                                marginTop: 30,
                              }}
                            />
                          </td>
                          <td className="sign">
                            <img
                              src={`https://easy.multigrad.in/media/?file=${signature}`}
                              width="140px"
                              height="140px"
                              style={{
                                float: "right",
                                marginLeft: 60,
                                padding: "30px",
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                width: "20%",
                marginLeft: "50%",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Button
                variant="primary"
                size="sm"
                type="submit"
                className="hide"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </>
    </>
  );
};

export default CreateBill;
