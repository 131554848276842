import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Checkpoint = (prop) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const userID = localStorage.getItem("userID");

  const [invoiceNumberError, setInvoiceNumberError] = useState("");
  const [invoiceDateError, setInvoiceDateError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.AdminPanelId) {
      setInvoiceNumberError("Please enter the CHA Office.");
      return;
    }

    if (!formData.companyName) {
      setInvoiceDateError("Please select the company Name");
      return;
    }

    let data = JSON.stringify({
      AdminPanelId: formData.AdminPanelId,
      companyName: formData.companyName,
    });
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/updateReg/${userID}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.message === "success") {
          navigate("/allinvoice");
        } else {
          // Handle error response if needed
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    prop.setShow(false);
  };

  return (
    <Modal
      show={prop.show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Please select your CHA office details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your company name"
              name="companyName"
              onChange={handleChange}
              value={formData.companyName}
              required
            />
            {invoiceNumberError && (
              <p className="error-message">{invoiceNumberError}</p>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>CHA Office</Form.Label>
            <Form.Select onChange={handleChange} name="AdminPanelId">
              <option>Choose</option>
              <option value="10001">Sukhuma, EasyExport</option>
              <option value="10002">Airtrax, EasyExport</option>
              <option value="10003">Sumukha freight forwarders</option>
              <option value="10004">Airtrax</option>
            </Form.Select>
            {invoiceDateError && (
              <p className="error-message">{invoiceDateError}</p>
            )}
          </Form.Group>

          <br />
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default Checkpoint;
