import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../assets/css/showbill.css";
import axios from "axios";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
const AllinvoiceModal = (prop) => {
  console.log(prop.userId);
  const handleClose = () => {
    prop.setModalShow(false);
  };

  const [extractData, setExtractData] = useState({});
  console.log(extractData);
  const getExtValue = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://easy.multigrad.in/api/shipping-bill-extracted/${prop.userId}`,
      headers: {
        token:
          "20b36f0f18fe59b51041f1c8e049007242fb40e53e25d093f5b652f9039f527b",
      },
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        console.log(response.data.data);
        setExtractData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getExtValue();
  }, []);

  const extractInvoiceNumber = (data, delimiter) => {
    const parts = data.split(delimiter);
    return parts[0];
  };
  const extractInvoiceDate = (data, delimiter) => {
    const parts = data.split(delimiter);
    return parts[1];
  };

  const [invoiceId, setInvoiceId] = useState("");
  const handleSubmit = (e) => {
    console.log(extractData);
    e.preventDefault();
    let data = JSON.stringify({
      egmNumber: extractData.egmNumber,
      createdDate: extractData.createdDate,
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `https://easy.multigrad.in/api//shipping-bill-extracted/${prop.userId}`,
      headers: {
        "Content-Type": "application/json",
        token:
          "20b36f0f18fe59b51041f1c8e049007242fb40e53e25d093f5b652f9039f527b",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Successfully updated ",
          showConfirmButton: false,
          timer: 1500,
        });
        getExtValue();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    setExtractData({ ...extractData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Modal show={prop.modalShow} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>
          {/* comment for future */}

          {extractData && (
            <div
              className="download_shipping_bill"
              style={{
                padding: "10px",
              }}
            >
              <Form onSubmit={handleSubmit}>
                <table>
                  <tr>
                    <th>Company</th>
                    <th>Contact</th>
                  </tr>
                  <tr>
                    <td>Port Code</td>
                    <td>{extractData.portcode}</td>
                  </tr>
                  <tr>
                    <td>SB No</td>
                    <td>{extractData.sb_no}</td>
                  </tr>
                  <tr>
                    <td>Sb Date</td>
                    <td>{extractData.sb_data}</td>
                  </tr>
                  <tr>
                    <td>Invoice No </td>
                    <td>
                      {extractData.invoice_no_dt &&
                        extractInvoiceNumber(extractData.invoice_no_dt, " ")}
                    </td>
                  </tr>
                  <tr>
                    <td>Invoice Date </td>
                    <td>
                      {extractData.invoice_no_dt &&
                        extractInvoiceDate(extractData.invoice_no_dt, " ")}
                    </td>
                  </tr>
                  <tr>
                    <td>IEC</td>
                    <td>{extractData.iec_br}</td>
                  </tr>
                  <tr>
                    <td>Buyer Name</td>
                    <td>{extractData.buyer_name}</td>
                  </tr>
                  <tr>
                    <td>Buyer Address</td>
                    <td>{extractData.buyer_address}</td>
                  </tr>

                  <tr>
                    <td>Invoice Value</td>
                    <td>{extractData.invoice_value}</td>
                  </tr>
                  <tr>
                    <td>FOB Value</td>
                    <td>{extractData.fob_value}</td>
                  </tr>

                  <tr>
                    <td>Egm Number</td>
                    <td>
                      <input
                        type="text"
                        name="egmNumber"
                        value={extractData.egmNumber}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Egm Date</td>
                    <td>
                      <input
                        type="date"
                        name="createdDate"
                        value={extractData.createdDate}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                </table>
                <Button className="mt-3" type="submit">
                  Update{" "}
                </Button>
              </Form>
            </div>
          )}
        </Modal.Title>

        <Modal.Footer>
          {prop.shippingBillUploaded === "yes" ? (
            <div className="shippingDetails">
              <a
                href={prop.mediaData}
                target="_blank"
                download
                rel="noreferrer"
              >
                <Button variant="secondary">Download Shipping Bill</Button>
              </a>
            </div>
          ) : (
            <div className="shippingDetails not">
              Shipping bill not recieved Yet
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AllinvoiceModal;
