import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
import LogoImg from "../../assets/images/svg/logo.svg";
import Checkpoint from "../modals/Checkpoint";
const Login = (prop) => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("username") === null || "") {
      navigate("/");
    } else {
      navigate("/allinvoice");
    }
  }, []);

  useEffect(() => {
    prop.setShow(false);
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let data = JSON.stringify({
        email: formData.email,
        password: formData.password,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL}/login`,
        headers: {
          token: process.env.REACT_APP_TOKEN,
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios
        .request(config)
        .then((response) => {
          if (response.data.message === "success") {
            localStorage.setItem("username", response.data.data.email);
            localStorage.setItem("userID", response.data.data._id);
            localStorage.setItem(
              "AdminPanelId",
              response.data.data.AdminPanelId
            );

            let da = "";
            let con = {
              method: "get",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_URL}/getReg/${response.data.data._id}`,
              headers: {
                token: process.env.REACT_APP_TOKEN,
              },
              data: da,
            };
            axios
              .request(con)
              .then((res) => {
                res.data.data.map((value) => {
                  if (value.AdminPanelId == undefined) {
                    setShow(true);
                  } else {
                    localStorage.setItem(
                      "whatsappGroupId",
                      value.whatsappGroupId
                    );

                    navigate("/allinvoice");
                    setShow(false);
                    Swal.fire({
                      icon: "success",
                      title: "Welcome back",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const loginwithGoogle = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );
        let data = JSON.stringify({
          email: res.data.email,
          password: res.data.sub,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL}/login`,
          headers: {
            token: process.env.REACT_APP_TOKEN,
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios
          .request(config)
          .then((response) => {
            if (response.data.message === "success") {
              localStorage.setItem("username", res.data.email);
              localStorage.setItem("userID", response.data.data._id);
              localStorage.setItem(
                "AdminPanelId",
                response.data.data.AdminPanelId
              );
              let da = "";
              let con = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL}/getReg/${response.data.data._id}`,
                headers: {
                  token: process.env.REACT_APP_TOKEN,
                },
                data: da,
              };
              axios
                .request(con)
                .then((res) => {
                  res.data.data.map((value) => {
                    if (value.AdminPanelId == undefined) {
                      setShow(true);
                    } else {
                      localStorage.setItem(
                        "whatsappGroupId",
                        value.whatsappGroupId
                      );

                      navigate("/allinvoice");
                      setShow(false);
                      Swal.fire({
                        icon: "success",
                        title: "Welcome back",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (response.data.message === "failed") {
              let data = JSON.stringify({
                email: res.data.email,
                password: res.data.sub,
                user_type: "",
                emailVerification: "true",
              });

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL}/registration`,
                headers: {
                  token: process.env.REACT_APP_TOKEN,
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {
                  if (response.data.message === "success") {
                    localStorage.setItem("username", res.data.email);
                    localStorage.setItem("userID", response.data.data._id);
                    localStorage.setItem(
                      "AdminPanelId",
                      response.data.data.AdminPanelId
                    );
                    let da = "";
                    let con = {
                      method: "get",
                      maxBodyLength: Infinity,
                      url: `${process.env.REACT_APP_URL}/getReg/${response.data.data._id}`,
                      headers: {
                        token: process.env.REACT_APP_TOKEN,
                      },
                      data: da,
                    };
                    axios
                      .request(con)
                      .then((res) => {
                        res.data.data.map((value) => {
                          if (value.AdminPanelId === undefined) {
                            setShow(true);
                          } else {
                            // navigate("/allinvoice");
                            setShow(false);
                            Swal.fire({
                              icon: "success",
                              title: "Welcome back",
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          }
                        });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
  });

  // show, setShow

  return (
    <>
      {show && <Checkpoint setShow={setShow} show={show} />}
      <div class="wrapper">
        <div id="left">
          <div class="showcase">
            <div class="showcase-content">
              <img src={LogoImg} alt="logo" id="logologin" />
            </div>
          </div>
        </div>
        <div id="right">
          <div class="sign-in">
            <div class="logo">
              <h1>Welcome Back !</h1>
            </div>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="signupHomepage"
            >
              <Form.Group controlId="validationCustom03">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="xyz@gmail.com"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please fill your email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom03">
                <span>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    placeholder="password"
                    name="password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    type={passwordShown ? "text" : "password"}
                    className="rg-form"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please fill your password
                  </Form.Control.Feedback>
                  <span onClick={togglePasswordVisiblity}>
                    {passwordShown == true && (
                      <AiOutlineEyeInvisible className="eyeviewer">
                        {" "}
                      </AiOutlineEyeInvisible>
                    )}
                    {passwordShown == false && (
                      <AiOutlineEye className="eyeviewer"></AiOutlineEye>
                    )}
                  </span>
                </span>
              </Form.Group>
              <br />
              <Button type="submit">Submit form</Button>
            </Form>
            <div class="or"></div>

            <button id="google" onClick={loginwithGoogle}>
              <FcGoogle id="gicon" />
              Continue with Google
            </button>

            <button class="secondary-btn" onClick={handleSignup}>
              Create an account
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
