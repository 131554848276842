import React, { useEffect, useState } from "react";
import "../../assets/css/create.css";
import { Form } from "react-bootstrap";
import axios from "axios";
import Export from "../../assets/images/export.png";
import Bill from "../../assets/images/bill.png";

const ExportInvoiceBillDownload = (prop) => {
  const { pathname } = window.location;
  const clickId = pathname.split("/").pop();
  console.log(clickId);
  const userID = localStorage.getItem("userID");
  const [signature, setSignature] = useState();

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/signature/${userID}`,
        {
          headers: {
            token: process.env.REACT_APP_TOKEN,
          },
        }
      );

      setSignature(response.data.data.signature);
    } catch (error) {
      console.log(error);
    }
  };
  const initialItem = Array.from({ length: 30 }, () => ({
    descriptionOfGoods: "",
    hsnOrSac: "",
    kgs: "",
    quantity: "",
    unit: "",
    rate: "",
    totalAmount: "",
  }));

  const [companyName, setCompanyName] = useState();
  const getRegData = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/getReg/${userID}`, {
        headers: {
          token: process.env.REACT_APP_TOKEN,
        },
      })
      .then((response) => {
        response.data.data.map((value) => {
          setCompanyName(value.companyName);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [getInvoiceBillData, setInvoiceBillData] = useState({
    items: [initialItem],
  });
  const getInvoiceBill = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/bill/${clickId}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setInvoiceBillData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getInvoiceBill();
  }, []);

  const pathnames = window.location.pathname;
  const id = pathnames.substring(pathname.lastIndexOf("/") + 1);
  const [isDownloaded, setIsDownloaded] = useState(false);
  useEffect(() => {
    if (id !== "" && getInvoiceBillData.accountName !== "" && !isDownloaded) {
      const timeout = setTimeout(() => {
        window.print();
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [getInvoiceBillData.accountName, isDownloaded]);

  useEffect(() => {
    prop.setShow(true);
    getRegData();
    fetchData();
  }, []);
  return (
    <>
      <>
        <div className="printDivArea">
          <p
            className="s1"
            style={{
              paddingLeft: "158pt",
              marginLeft: "14%",
            }}
          >
            Supply meant for Export on N.A.
          </p>

          <Form>
            <table
              style={{ borderCollapse: "collapse", marginLeft: "5.65pt" }}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "111pt",
                        paddingRight: "126pt",
                        textIndent: "0pt",
                        textAlign: "center",
                        fontFamily: "bl-regular",
                      }}
                    >
                      EXPORT INVOICE
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "24pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s3"
                      style={{
                        paddingLeft: "128pt",
                        paddingRight: "126pt",
                        textIndent: "0pt",
                        lineHeight: "23pt",
                        textAlign: "center",
                        fontWeight: 900,
                        fontSize: "40px",
                        fontFamily: "bl-bold",
                        padding: "10px",
                      }}
                    >
                      {companyName}
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "372pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={4}
                    rowSpan={7}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "154pt",
                        textIndent: "0pt",
                        lineHeight: "119%",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      Address:{" "}
                      <input
                        className="input_c_bill"
                        name="address"
                        value={getInvoiceBillData.address}
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      District:{" "}
                      <input
                        className="input_c_bill"
                        name="district"
                        value={getInvoiceBillData.district}
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      State:{" "}
                      <input
                        className="input_c_bill"
                        name="state"
                        value={getInvoiceBillData.state}
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      Contact No:{" "}
                      <input
                        className="input_c_bill"
                        name="contactNumber"
                        value={getInvoiceBillData.contactNumber}
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      E-mail:{" "}
                      <input
                        className="input_c_bill"
                        value={getInvoiceBillData.emailAddress}
                        name="emailAddress"
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      PAN:{" "}
                      <input
                        className="input_c_bill"
                        value={getInvoiceBillData.panNumber}
                        name="panNumber"
                      />
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      GST:{" "}
                      <input
                        className="input_c_bill"
                        value={getInvoiceBillData.gstNumber}
                        name="gstNumber"
                      />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Invoice No:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "25pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="invoiceNumber"
                        value={getInvoiceBillData.invoiceNumber}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                      fontFamily: "bl-regular",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "17pt",
                        textIndent: "0pt",
                        textAlign: "left",
                        fontFamily: "bl-regular",
                      }}
                    >
                      Date:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="createDate"
                        value={getInvoiceBillData.createDate}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Vehicle No:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "33pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="vehicleNumber"
                        value={getInvoiceBillData.vehicleNumber}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "16pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Driver:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "22pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="driverNumber"
                        value={getInvoiceBillData.driverNumber}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      No. of Package:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "33pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="numberOfPackages"
                        value={getInvoiceBillData.numberOfPackages}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "3pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Gross Weight:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="grossWeight"
                        value={getInvoiceBillData.grossWeight}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "60pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "7pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Net Weight:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "41pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="netWeight"
                        value={getInvoiceBillData.netWeight}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "15pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s5"
                      style={{
                        paddingLeft: "128pt",
                        paddingRight: "126pt",
                        textIndent: "0pt",
                        lineHeight: "13pt",
                        textAlign: "center",
                      }}
                    >
                      I.E.CODE: AHZPY7946H AD CODE: 0270945-1010286
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "15pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s6"
                      style={{
                        paddingLeft: "2pt",
                        textIndent: "0pt",
                        lineHeight: "14pt",
                        textAlign: "left",
                      }}
                    >
                      To:
                      <input
                        className="input_c_bill"
                        name="billingTo"
                        value={getInvoiceBillData.billingTo}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "280pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      lineHeight: "1rem",
                      borderRightColor: "#808080",
                      marginTop: "10px",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      TPN:{" "}
                      <span className="s7">
                        <input
                          className="input_c_bill"
                          name="tpnNumber"
                          value={getInvoiceBillData.tpnNumber}
                        />
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      width: "92pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "29pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Contact:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "182pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={4}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <input
                        className="input_c_bill"
                        name="billingContact"
                        value={getInvoiceBillData.billingContact}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "18pt" }}>
                  <td
                    style={{
                      width: "554pt",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={8}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Address:{" "}
                      <span className="s8">
                        <input
                          className="input_c_bill"
                          name="billingAddress"
                          value={getInvoiceBillData.billingAddress}
                        />
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "21pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "3pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      S.n.
                    </p>
                  </td>
                  <td
                    style={{
                      width: "259pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "86pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Description of Goods
                    </p>
                  </td>
                  <td
                    style={{
                      width: "49pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      HSN/SAC
                    </p>
                  </td>
                  <td
                    style={{
                      width: "43pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Packing
                    </p>
                  </td>
                  <td
                    style={{
                      width: "33pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Qnty
                    </p>
                  </td>
                  <td
                    style={{
                      width: "27pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "5pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      Unit
                    </p>
                  </td>
                  <td
                    style={{
                      width: "50pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "5pt",
                        paddingRight: "3pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "center",
                      }}
                    >
                      Rate
                    </p>
                  </td>
                  <td
                    style={{
                      width: "72pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "19pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "right",
                      }}
                    >
                      Amount
                    </p>
                  </td>
                </tr>

                {Array.from({ length: 30 }, (_, index) => {
                  const item = getInvoiceBillData.items[index];

                  return (
                    <tr key={index}>
                      <td
                        style={{
                          width: "21pt",
                          borderTopStyle: "solid",
                          borderTopWidth: "0pt",
                          borderTopColor: "#808080",
                          borderLeftStyle: "solid",
                          borderLeftWidth: "1pt",
                          borderLeftColor: "#808080",
                          borderBottomStyle: "solid",
                          borderBottomWidth: "0pt",
                          borderBottomColor: "#808080",
                          borderRightStyle: "solid",
                          borderRightWidth: "1pt",
                          borderRightColor: "#808080",
                        }}
                      >
                        <p
                          className="s8"
                          style={{
                            paddingLeft: "8pt",
                            textIndent: "0pt",
                            textAlign: "left",
                            fontSize: "18px",
                          }}
                        >
                          {index + 1}
                        </p>
                      </td>
                      <td
                        style={{
                          width: "259pt",
                          borderTopStyle: "solid",
                          borderTopWidth: "0pt",
                          borderTopColor: "#808080",
                          borderLeftStyle: "solid",
                          borderLeftWidth: "1pt",
                          borderLeftColor: "#808080",
                          borderBottomStyle: "solid",
                          borderBottomWidth: "0pt",
                          borderBottomColor: "#808080",
                          borderRightStyle: "solid",
                          borderRightWidth: "1pt",
                          borderRightColor: "#808080",
                        }}
                      >
                        <p
                          className="s8"
                          style={{
                            paddingLeft: "1pt",
                            paddingRight: "194pt",
                            textIndent: "0pt",
                            lineHeight: "119%",
                            textAlign: "left",
                            fontSize: "18px",
                          }}
                        >
                          <input
                            className="input_c_bill"
                            name="descriptionOfGoods"
                            value={item ? item.descriptionOfGoods : ""}
                          />
                        </p>
                      </td>
                      <td
                        style={{
                          width: "49pt",
                          borderTopStyle: "solid",
                          borderTopWidth: "0pt",
                          borderTopColor: "#808080",
                          borderLeftStyle: "solid",
                          borderLeftWidth: "1pt",
                          borderLeftColor: "#808080",
                          borderBottomStyle: "solid",
                          borderBottomWidth: "0pt",
                          borderBottomColor: "#808080",
                          borderRightStyle: "solid",
                          borderRightWidth: "1pt",
                          borderRightColor: "#808080",
                        }}
                      >
                        <p
                          className="s8"
                          style={{
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "left",
                            fontSize: "18px",
                          }}
                        >
                          <input
                            className="item_c_bill"
                            name="hsnOrSac"
                            value={item ? item.hsnOrSac : ""}
                          />
                        </p>
                      </td>
                      <td
                        style={{
                          width: "43pt",
                          borderTopStyle: "solid",
                          borderTopWidth: "0pt",
                          borderTopColor: "#808080",
                          borderLeftStyle: "solid",
                          borderLeftWidth: "1pt",
                          borderLeftColor: "#808080",
                          borderBottomStyle: "solid",
                          borderBottomWidth: "0pt",
                          borderBottomColor: "#808080",
                          borderRightStyle: "solid",
                          borderRightWidth: "1pt",
                          borderRightColor: "#808080",
                        }}
                      >
                        <p
                          className="s8"
                          style={{
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            textAlign: "left",
                            fontSize: "18px",
                          }}
                        >
                          <input
                            className="item_c_bill"
                            name="unit"
                            value={item ? item.unit : ""}
                          />
                        </p>
                      </td>
                      <td
                        style={{
                          width: "33pt",
                          borderTopStyle: "solid",
                          borderTopWidth: "0pt",
                          borderTopColor: "red",
                          borderLeftStyle: "solid",
                          borderLeftWidth: "1pt",
                          borderLeftColor: "#808080",
                          borderBottomStyle: "solid",
                          borderBottomWidth: "0pt",
                          borderBottomColor: "#808080",
                          borderRightStyle: "solid",
                          borderRightWidth: "1pt",
                          borderRightColor: "#808080",
                        }}
                      >
                        <p
                          className="s8"
                          style={{
                            paddingLeft: "9pt",
                            textIndent: "0pt",
                            textAlign: "left",
                            fontSize: "18px",
                          }}
                        >
                          <input
                            className="item_c_bill"
                            name="quantity"
                            value={item ? item.quantity : ""}
                          />
                        </p>
                      </td>
                      <td
                        style={{
                          width: "27pt",
                          borderTopStyle: "solid",
                          borderTopWidth: "0pt",
                          borderTopColor: "#808080",
                          borderLeftStyle: "solid",
                          borderLeftWidth: "1pt",
                          borderLeftColor: "#808080",
                          borderBottomStyle: "solid",
                          borderBottomWidth: "0pt",
                          borderBottomColor: "#808080",
                          borderRightStyle: "solid",
                          borderRightWidth: "1pt",
                          borderRightColor: "#808080",
                        }}
                      >
                        <p
                          className="s8"
                          style={{
                            paddingLeft: "6pt",
                            paddingRight: "3pt",
                            textIndent: "0pt",
                            lineHeight: "119%",
                            textAlign: "justify",
                            fontSize: "18px",
                          }}
                        >
                          <input
                            className="item_c_bill"
                            name="kgs"
                            value={item ? item.kgs : ""}
                          />
                        </p>
                      </td>
                      <td
                        style={{
                          width: "50pt",
                          borderTopStyle: "solid",
                          borderTopWidth: "0pt",
                          borderTopColor: "#808080",
                          borderLeftStyle: "solid",
                          borderLeftWidth: "1pt",
                          borderLeftColor: "#808080",
                          borderBottomStyle: "solid",
                          borderBottomWidth: "0pt",
                          borderBottomColor: "#808080",
                          borderRightStyle: "solid",
                          borderRightWidth: "1pt",
                          borderRightColor: "#808080",
                        }}
                      >
                        <p
                          className="s8"
                          style={{
                            textIndent: "0pt",
                            textAlign: "right",
                            fontSize: "18px",
                          }}
                        >
                          <input
                            className="item_c_bill"
                            name="rate"
                            value={item ? item.rate : ""}
                            style={{
                              outline: "none",
                              border: "none",
                              textAlign: "right",
                              borderRadius: 0,
                              marginRight: "0px",
                              float: "right",
                              backgroundColor: "white",
                            }}
                          />
                        </p>
                      </td>
                      <td
                        style={{
                          width: "72pt",
                          borderTopStyle: "solid",
                          borderTopWidth: "0pt",
                          borderTopColor: "#808080",
                          borderLeftStyle: "solid",
                          borderLeftWidth: "1pt",
                          borderLeftColor: "#808080",
                          borderBottomStyle: "solid",
                          borderBottomWidth: "0pt",
                          borderBottomColor: "#808080",
                          borderRightStyle: "solid",
                          borderRightWidth: "1pt",
                          borderRightColor: "#808080",
                        }}
                      >
                        <p
                          className="s8"
                          style={{
                            textIndent: "0pt",
                            textAlign: "center",
                            fontSize: "18px",
                          }}
                        >
                          <input
                            value={item ? item.totalAmount : ""}
                            style={{
                              outline: "none",
                              border: "none",
                              textAlign: "right",
                              borderRadius: 0,
                              marginRight: "0px",
                              float: "right",
                              fontSize: "18px",
                              backgroundColor: "white",
                            }}
                            name="totalAmount"
                            disabled
                          />
                        </p>
                      </td>
                    </tr>
                  );
                })}

                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "280pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{ textIndent: "0pt", textAlign: "right" }}
                    >
                      Sub Total
                    </p>
                  </td>
                  <td
                    style={{
                      width: "49pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "43pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "33pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s8"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      {/* 2149 */}
                    </p>
                  </td>
                  <td
                    style={{
                      width: "27pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s8"
                      style={{
                        paddingLeft: "6pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      {/* KGS */}
                    </p>
                  </td>
                  <td
                    style={{
                      width: "50pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "72pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s8"
                      style={{
                        paddingLeft: "22pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="subTotal"
                        id="subTotal"
                        value={getInvoiceBillData.subTotal}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "4pt" }}>
                  <td
                    style={{
                      width: "432pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={6}
                    rowSpan={3}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingTop: "4pt",
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      Rupees:
                    </p>
                  </td>
                  <td
                    style={{
                      width: "122pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "50pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s9"
                      style={{
                        paddingTop: "1pt",
                        paddingLeft: "5pt",
                        paddingRight: "3pt",
                        textIndent: "0pt",
                        textAlign: "center",
                      }}
                    >
                      Round Off
                    </p>
                  </td>
                  <td
                    style={{
                      width: "72pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14pt" }}>
                  <td
                    style={{
                      width: "50pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "5pt",
                        paddingRight: "3pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "center",
                      }}
                    >
                      G.TOTAL
                    </p>
                  </td>
                  <td
                    style={{
                      width: "72pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "12pt",
                        textIndent: "0pt",
                        lineHeight: "12pt",
                        textAlign: "left",
                      }}
                    >
                      <input
                        className="input_c_bill"
                        name="subTotal"
                        id="subTotal"
                        value={getInvoiceBillData.subTotal}
                      />
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "72pt" }}>
                  <td
                    style={{
                      width: "280pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={2}
                  >
                    <p
                      className="s2"
                      style={{
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      COMPANY BANK DETAILS:
                    </p>
                    <p
                      className="s8"
                      style={{
                        paddingTop: "2pt",
                        paddingLeft: "1pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      BANK NAME:
                      <input
                        className="input_c_bill"
                        name="bankName"
                        value={getInvoiceBillData.bankName}
                      />
                    </p>
                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      A/C Name
                      <input
                        className="input_c_bill"
                        name="accountName"
                        value={getInvoiceBillData.accountName}
                      />
                    </p>

                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      BRANCH NAME
                      <input className="input_c_bill" />
                    </p>

                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      ACCOUNT NO
                      <input
                        className="input_c_bill"
                        name="bankNumber"
                        value={getInvoiceBillData.bankNumber}
                      />
                    </p>

                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      IFSC CODE
                      <input
                        className="input_c_bill"
                        value={getInvoiceBillData.ifscCode}
                        name="ifscCode"
                      />
                    </p>

                    <p
                      className="s8"
                      style={{
                        paddingLeft: "1pt",
                        paddingRight: "19pt",
                        textIndent: "0pt",
                        lineHeight: "15pt",
                        textAlign: "left",
                      }}
                    >
                      AD CODE
                      <input
                        className="input_c_bill"
                        name="adCode"
                        value={getInvoiceBillData.adCode}
                      />
                    </p>
                  </td>
                  <td
                    style={{
                      width: "152pt",
                      borderTopStyle: "solid",
                      borderTopWidth: "1pt",
                      borderTopColor: "#808080",
                      borderLeftStyle: "solid",
                      borderLeftWidth: "1pt",
                      borderLeftColor: "#808080",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1pt",
                      borderBottomColor: "#808080",
                      borderRightStyle: "solid",
                      borderRightWidth: "1pt",
                      borderRightColor: "#808080",
                    }}
                    colSpan={6}
                  >
                    <p
                      style={{
                        paddingLeft: "2pt",
                        textIndent: "0pt",
                        textAlign: "left",
                      }}
                    >
                      <span></span>
                    </p>
                    <table border={0} cellSpacing={0} cellPadding={0}>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src={Export}
                              width="120px"
                              height="120px"
                              style={{
                                float: "right",
                                marginLeft: 20,
                                marginTop: 30,
                                padding: "0px",
                              }}
                            />
                          </td>
                          <td>
                            <img
                              src={Bill}
                              width="120px"
                              height="120px"
                              style={{
                                float: "right",
                                marginLeft: 0,
                                padding: "0px",
                                marginTop: 30,
                              }}
                            />
                          </td>
                          <td className="sign">
                            <img
                              src={`https://easy.multigrad.in/media/?file=${signature}`}
                              width="140px"
                              height="140px"
                              style={{
                                float: "right",
                                marginLeft: 60,
                                padding: "30px",
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                width: "20%",
                marginLeft: "50%",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              {/* <Button
                                variant="primary"
                                size="sm"
                                type="submit"
                                className="hide"
                            >
                                Save
                            </Button> */}
            </div>
          </Form>
        </div>
      </>
    </>
  );
};

export default ExportInvoiceBillDownload;
