import axios from "axios";
import Swal from "sweetalert2";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoImg from "../../assets/images/svg/logo.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
const SignUp = (prop) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  const createAccount = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      // console.log("working");
      let data = JSON.stringify({
        email: formData.email,
        password: formData.password,
        mobile: formData.password,
        fullName: formData.fullname,
        AdminPanelId: formData.AdminPanelId,
        user_type: "user",
        companyName: formData.companyName,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL}/registration`,
        headers: {
          token: process.env.REACT_APP_TOKEN,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data.message === "success") {
            localStorage.setItem("username", response.data.data.email);
            localStorage.setItem("userID", response.data.data._id);
            localStorage.setItem(
              "AdminPanelId",
              response.data.data.AdminPanelId
            );
            navigate("/allinvoice");
            Swal.fire({
              icon: "success",
              title: "Account Created Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log(e.target.name);
    // console.log(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // navigate("/");
  };

  return (
    <div className="wrapper">
      <div id="left">
        <div className="showcase">
          <div className="showcase-content">
            <img src={LogoImg} alt="logo" id="logologin" />
          </div>
        </div>
      </div>
      <div id="right">
        <div className="sign-in signup">
          <div className="logo">
            <h1>Create New Account?</h1>
          </div>
          <Form
            noValidate
            validated={validated}
            classNameName="signupHomepage"
            onSubmit={createAccount}
          >
            <Form.Group controlId="validationCustom03">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullname"
                placeholder="Type Here.."
                required
                value={formData.fullname}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="validationCustom03">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="xyz@gmail.com"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please fill your email.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Compnay Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your company name"
                name="companyName"
                onChange={handleChange}
                value={formData.companyName}
              />
            </Form.Group>

            <Form.Group classNameName="mb-3" controlId="formBasicEmail">
              <Form.Label>CHA Office</Form.Label>
              {/* <Form.Control type="date" placeholder="Enter lut date" /> */}

              <Form.Select onChange={handleChange} name="AdminPanelId">
                <option>Choose</option>
                <option value="10001">Sukhuma, EasyExport</option>
                <option value="10002">Airtrax, EasyExport</option>
                <option value="10003">Sumukha freight forwarders</option>
                <option value="10004">Airtrax</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="validationCustom03">
              <span>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  placeholder="password"
                  name="password"
                  required
                  value={formData.password}
                  onChange={handleChange}
                  type="password"
                  classNameName="rg-form"
                />
                <Form.Control.Feedback type="invalid">
                  Please fill your password
                </Form.Control.Feedback>
              </span>
            </Form.Group>
            <br />
            <Button type="submit">Create Account</Button>
          </Form>

          {/* <button type='button' className="secondary-btn" onClick={handleLogin}>
            Login
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
