import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PDF from "../../assets/images/svg/pdf.svg";
import EXCEL from "../../assets/images/svg/excel.svg";
import Declaration from "../../assets/images/svg/declaration.svg";
import Eway from "../../assets/images/svg/eway.svg";
import shipping from "../../assets/images/svg/shipping.png";
import Other from "../../assets/images/svg/Other.svg";
import AllinvoiceModal from "../modals/ViewShippingBillDetailsModal";
import "react-circular-progressbar/dist/styles.css";
import UploadShippingBill from "../modals/UploadShippingBill";
import InVoiceUpload from "../modals/InVoiceUpload";

const AllInvoice = (prop) => {
  const [signature, setSignature] = useState(null);
  const [autoSign, setAutoSign] = useState(null);
  const [userVerification, setUserVerification] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const handleShow = () => setShowUpload(true);
  const navigate = useNavigate();
  useEffect(() => {
    prop.setShow(true);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/signature/${userID}`,
          {
            headers: {
              token: process.env.REACT_APP_TOKEN,
            },
          }
        );

        setSignature(response.data.data.signature);
      } catch (error) {}
    };

    const fetchDataAuto = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/getReg/${userID}`,
          {
            headers: {
              token: process.env.REACT_APP_TOKEN,
            },
          }
        );

        setAutoSign(response.data.data[0].autoSign);
        setUserVerification(response.data.data[0].userVerification);
        SetAdminPanelId(response.data.data[0].whatsappGroupId);
      } catch (error) {}
    };

    fetchData();
    fetchDataAuto();
  }, []);

  const userID = localStorage.getItem("userID");
  const [arrayData, setArrayData] = useState([]);
  const getInvoice = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/get-inovice/${userID}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setArrayData(response.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getInvoice();
    if (localStorage.getItem("username") == null) {
      navigate("/");
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();

  const sentToCha = (e, value) => {
    e.preventDefault();

    let data9 = JSON.stringify({
      whatsappGroupId: AdminPanelId,
    });

    let config9 = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `https://easy.multigrad.in/api/updateDetails/${value._id}`,
      headers: {
        token:
          "20b36f0f18fe59b51041f1c8e049007242fb40e53e25d093f5b652f9039f527b",
        "Content-Type": "application/json",
      },
      data: data9,
    };

    axios
      .request(config9)
      .then((response) => {})
      .catch((error) => {});

    let data = JSON.stringify({
      sent_to_cha: "yes",
      sendingInvoiceDate: date,
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/updateDetails/${value._id}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.message === "success") {
          getInvoice();
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
          }, 4000);
        }
      })
      .catch((error) => {});

    if (autoSign === "on") {
      let dataNew = JSON.stringify({
        fileName: value.invoiceFile,
        waterMark: signature,
      });
      let configNew = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL}/watermark`,
        headers: {
          token: process.env.REACT_APP_TOKEN,
          "Content-Type": "application/json",
        },
        data: dataNew,
      };
      axios
        .request(configNew)
        .then((response) => {
          if (response.data.message === "success") {
            getInvoice();
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
            }, 4000);
          }
        })
        .catch((error) => {});
    }

    if (autoSign === "on") {
      let dataNew = JSON.stringify({
        fileName: value.other_file,
        waterMark: signature,
      });
      let configNew = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL}/watermark`,
        headers: {
          token: process.env.REACT_APP_TOKEN,
          "Content-Type": "application/json",
        },
        data: dataNew,
      };
      axios
        .request(configNew)
        .then((response) => {
          if (response.data.message === "success") {
            getInvoice();
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
            }, 4000);
          }
        })
        .catch((error) => {});
    }

    if (autoSign === "on") {
      let dataNew = JSON.stringify({
        fileName: value.invoicePdfFile,
        waterMark: signature,
      });
      let configNew = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL}/watermark`,
        headers: {
          token: process.env.REACT_APP_TOKEN,
          "Content-Type": "application/json",
        },
        data: dataNew,
      };
      axios
        .request(configNew)
        .then((response) => {
          if (response.data.message === "success") {
            getInvoice();
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
            }, 4000);
          }
        })
        .catch((error) => {});
    }

    let dataNew = JSON.stringify({
      path: value.invoiceExcelFile,
    });
    let configNew = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://excel.multigrad.in/convert-${value.billType}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataNew,
    };
    axios
      .request(configNew)
      .then((response) => {})
      .catch((error) => {});

    let hsnNew = JSON.stringify({
      path: value.invoiceExcelFile,
    });
    let hsn = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://excel.multigrad.in/convert-hsn`,
      headers: {
        "Content-Type": "application/json",
      },
      data: hsnNew,
    };
    axios
      .request(hsn)
      .then((response) => {})
      .catch((error) => {});

    let dataPDF = JSON.stringify({
      to_number: AdminPanelId,
      type: "media",
      filename: value.invoiceNumber + ".pdf",
      message: `https://easy.multigrad.in/media/?file=${value.invoiceFile}`,
      text: `Received Invoice: ` + value.invoiceNumber,
    });

    let configPDF = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.maytapi.com/api/6a4fdb19-272f-48d8-9792-783b32ee3beb/30170/sendMessage",
      headers: {
        "Content-Type": "application/json",
        "x-maytapi-key": "8753b0a9-761a-4b16-8f17-dec1994ba58b",
      },
      data: dataPDF,
    };

    axios
      .request(configPDF)
      .then((response) => {})
      .catch((error) => {});
  };

  const undoBill = (e, value) => {
    e.preventDefault();
    let data = JSON.stringify({
      sent_to_cha: "no",
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/updateDetails/${value._id}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  const [shippingBill, setShippingBill] = useState([]);
  const [mediaData, setMediaData] = useState();
  const [categoryInvoice, setcategoryInvoice] = useState();
  const [buyerName, setbuyerName] = useState();
  const [shippingBillNumber, setshippingBillNumber] = useState();
  const [shippingBillDate, setshippingBillDate] = useState();
  const [portCode, setportCode] = useState();
  const [fobValueOrSubtotal, setfobValueOrSubtotal] = useState();
  const [totalInvoiceValue, settotalInvoiceValue] = useState();
  const [shippingBillFile, setshippingBillFile] = useState();
  const [shippingBillUploaded, setshippingBillUploaded] = useState();
  const [AdminPanelId, SetAdminPanelId] = useState();
  const [userId, setuserId] = useState();
  const [invoiceId, setInvoiceId] = useState();

  const Shipping = (e, value) => {
    e.preventDefault();
    const FormData = require("form-data");
    let data = new FormData();
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/shipping-bill/${value._id}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setModalShow(true);
        response.data.data.map((val, index) => {
          setMediaData(val.shippingBillFile);
          setcategoryInvoice(val.categoryInvoice);
          setbuyerName(val.buyerName);
          setshippingBillNumber(val.shippingBillNumber);
          setshippingBillDate(val.shippingBillDate);
          setfobValueOrSubtotal(val.fobValueOrSubtotal);
          settotalInvoiceValue(val.totalInvoiceValue);
          setshippingBillFile(val.shippingBillFile);
          setshippingBillUploaded(val.shippingBillUploaded);
          setuserId(val.userId);
          setInvoiceId(val._id);
        });
      })
      .catch((error) => {});
  };

  const [editableData, setEditableData] = useState({});
  const handleEdit = (e, value) => {
    e.preventDefault();
    setEditableData(value);
    setShowUpload(true);
  };
  const [billShow, setBillShow] = useState(false);
  const [billData, setBillData] = useState({});
  const handleUploadBill = (e, value) => {
    setBillShow(true);
    setBillData(value);
    let data = JSON.stringify({
      shipping_bill_recieved: "yes",
    });
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/updateDetails/${value._id}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {})
      .catch((error) => {});
  };

  const handleDelete = (e, value) => {
    e.preventDefault();
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      let data = "";
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL}/invoice/${value._id}`,
        headers: {
          token: process.env.REACT_APP_TOKEN,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          getInvoice();
        })
        .catch((error) => {});
    }
  };

  return (
    <>
      {showUpload && (
        <InVoiceUpload
          setShowUpload={setShowUpload}
          showUpload={showUpload}
          getInvoice={getInvoice}
          editableData={editableData}
          setEditableData={setEditableData}
          whatsappGroupId={AdminPanelId}
        />
      )}
      {billShow && (
        <UploadShippingBill
          setBillShow={setBillShow}
          billShow={billShow}
          billData={billData}
        />
      )}
      {modalShow && (
        <AllinvoiceModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          mediaData={mediaData}
          categoryInvoice={categoryInvoice}
          buyerName={buyerName}
          shippingBillNumber={shippingBillNumber}
          shippingBillDate={shippingBillDate}
          portCode={portCode}
          fobValueOrSubtotal={fobValueOrSubtotal}
          totalInvoiceValue={totalInvoiceValue}
          shippingBillFile={shippingBillFile}
          shippingBillUploaded={shippingBillUploaded}
          userId={userId}
          id={invoiceId}
        />
      )}
      <div>
        {isLoading ? <div className="loader" /> : null}
        <div className="container">
          <div className="billing_history">
            <div className="home_inner_first">
              <div className="home_left">
                <h3>Shipping Bill & Export Invoice</h3>
                <p></p>
              </div>
              <div className="home_right">
                <Button onClick={handleShow}>Upload</Button>
              </div>
            </div>
          </div>

          <div className="invoice_table">
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Form.Check />
                  </th>
                  <th>Invoice Date</th>
                  <th>Invoice Details</th>
                  <th>Documents Uploaded</th>
                  <th>Document Status</th>
                  <th>Order Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {arrayData?.map((value) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <Form.Check />
                        </td>
                        <td>
                          {moment
                            .utc(value.created_date)
                            .tz("Asia/Kolkata")
                            .format("DD/MMMM/YYYY")}
                        </td>
                        <td>
                          {/* <p>{value._id}</p> */}
                          <p>{value.invoiceNumber}</p>
                          <p>{value.vehicleNumber}</p>
                        </td>
                        <td className="uploaded">
                          {value.invoiceFile !== null && (
                            <a
                              href={`https://easy.multigrad.in/media/?file=${value.invoiceFile}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p>
                                <img id="pdf" src={PDF} alt="PDF" />
                              </p>
                            </a>
                          )}

                          {value.invoiceExcelFile !== null && (
                            <a
                              href={`https://easy.multigrad.in/media/?file=${value.invoiceExcelFile}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p>
                                <img id="pdf" src={EXCEL} alt="PDF" />
                              </p>
                            </a>
                          )}

                          {value.invoiceExcelFile !== null && (
                            <a
                              href={`https://easy.multigrad.in/media/?file=output_${value.invoiceExcelFile}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p>
                                <img id="pdf" src={EXCEL} alt="PDF" />
                              </p>
                            </a>
                          )}
                          {value.invoicePdfFile !== null && (
                            <a
                              href={`https://easy.multigrad.in/media/?file=${value.invoicePdfFile}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p>
                                <img id="pdf" src={Declaration} alt="PDF" />
                              </p>
                            </a>
                          )}

                          {value.eway_file !== null && (
                            <a
                              href={`https://easy.multigrad.in/media/?file=${value.eway_file}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p>
                                <img id="pdf" src={Eway} alt="PDF" />
                              </p>
                            </a>
                          )}

                          {value.other_file !== null && (
                            <a
                              href={`https://easy.multigrad.in/media/?file=${value.other_file}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p>
                                <img id="pdf" src={Other} alt="PDF" />
                              </p>
                            </a>
                          )}
                        </td>
                        <td>
                          <p>
                            {value.invoiceFile === null && (
                              <>Invoice Pdf Pending</>
                            )}
                          </p>
                          <p>
                            {value.invoiceExcelFile === null && (
                              <>Invoice Excel Pending</>
                            )}
                          </p>
                          <p>
                            {value.eway_file === null && (
                              <>E-Way Bill Pending</>
                            )}
                          </p>
                          <p>
                            {value.invoicePdfFile !== null &&
                              value.invoiceExcelFile !== null &&
                              value.eway_file !== null && <>Completed</>}
                          </p>
                        </td>
                        <td>
                          <p>
                            {value.sent_to_cha === "yes" ? (
                              <>Sent to CHA</>
                            ) : (
                              <>Pending </>
                            )}
                          </p>
                          {value.shipping_bill_recieved === "yes" && (
                            <>
                              <img src={shipping} alt="shipping" width="50" />
                            </>
                          )}
                        </td>

                        <td>
                          {value.sent_to_cha === "no" &&
                          userVerification === "approve" ? (
                            <p>
                              <Form>
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) => sentToCha(e, value)}
                                >
                                  Send to CHA
                                </button>
                              </Form>
                            </p>
                          ) : (
                            value.sent_to_cha === "yes" &&
                            userVerification === "approve" && (
                              <p>
                                <Form>
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) => undoBill(e, value)}
                                  >
                                    Undo
                                  </button>
                                </Form>
                              </p>
                            )
                          )}

                          <p></p>

                          <p>
                            <Form>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => handleEdit(e, value)}
                              >
                                Edit Bill
                              </button>
                            </Form>
                          </p>
                          <p>
                            <a
                              href={`https://easy.multigrad.in/media/?file=${shippingBill}`}
                              target="_blank"
                              download
                              rel="noreferrer"
                            >
                              <button
                                className="btn btn-primary"
                                onClick={(e) => Shipping(e, value)}
                              >
                                View Shipping Bill Details
                              </button>
                            </a>
                          </p>

                          {value.sent_to_cha !== "yes" && (
                            <p>
                              <Form>
                                <Button
                                  onClick={(e) => handleUploadBill(e, value)}
                                >
                                  Upload Shipping Bill
                                </Button>
                              </Form>
                            </p>
                          )}
                          <p>
                            <Form>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => handleDelete(e, value)}
                              >
                                Delete
                              </button>
                            </Form>
                          </p>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <br />
        </div>
      </div>
    </>
  );
};

export default AllInvoice;
