import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Text,
  Row,
  Col,
  InputGroup,
  Table,
} from "react-bootstrap";

import { FiDownloadCloud, FiUploadCloud } from "react-icons/fi";
// FiUploadCloud

const UploadDetail = () => {
  const [show, setShow] = useState(false);
  const handleUpload = () => {
    setShow(true);
  };
  return (
    <div>
      <div className="container">
        <h1 id="your_setting">Upload EGM Details</h1>
        <div className="billing_history">
          <div className="home_inner_first">
            <div className="home_left">
              <h3>List of invoices</h3>
              <p></p>
            </div>
            <div className="home_right">
              <Button>
                {" "}
                <FiDownloadCloud style={{ color: "white" }} />
                &nbsp; Download All
              </Button>
            </div>
          </div>
        </div>

        <div className="invoice_table">
          <Table responsive>
            <thead>
              <tr>
                <th>
                  {" "}
                  <Form.Check />
                </th>
                <th></th>
                <th>Receiving Date</th>
                <th>Sender Name</th>
                <th>Invoice Number</th>
                <th>Status</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Form.Check />
                <td>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/640px-PDF_file_icon.svg.png"
                    width="30px"
                  />
                </td>
                <td>12/12/2023</td>
                <td>Dharmendra</td>
                <td>ESYXPRT001</td>
                <td>
                  {" "}
                  <Form.Select
                    aria-label="Status"
                    // value={selectedItem}
                    // onChange={handleChange}
                  >
                    <option>Upload Egm Details</option>
                    <option>Completed Invoices</option>
                    {/* <option>Under Progess</option>
                    <option>Sent to Rectification</option>
                    <option value="">Rectified Invoices</option> */}
                  </Form.Select>
                </td>

                {/* <td className="inner_btn">
                  <Button onClick={handleUpload}>
                    <FiUploadCloud /> Upload
                  </Button>
                </td> */}
                <td className="inner_btn" style={{ display: "flex" }}>
                  <Button className="button_tabel">
                    <FiDownloadCloud /> Start Process
                  </Button>
                  <Button className="button_tabel" onClick={handleUpload}>
                    <FiUploadCloud /> Upload
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default UploadDetail;
