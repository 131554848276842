import React, { useEffect, useState, lazy } from "react";
import AllInvoice from "./screen/authScreens/AllInvoice";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./screen/authentication/Login";
import Signup from "./screen/authentication/SignUp";
import "../src/assets/css/desktop.css";
import Header from "./screen/commonScreens/Header";
import VerifyShipiingBill from "./screen/authScreens/VerifyShipiingBill";
import UploadDetail from "./screen/authScreens/UploadDetail";
import Settings from "./screen/authScreens/Settings";
import AdminLogin from "./screen/AdminLogin/AdminLogin";
import CreateBill from "./screen/authScreens/CreateBill";
import ExportInvoiceGet from "./screen/authScreens/ExportInvoiceGet";
import ExportInvoiceBillDownload from "./screen/authScreens/ExportInvoiceBillDownload";
import ShippingBillExtractedTable from "./screen/authScreens/ShippingBillExtractedTable";

// ExportInvoiceGet

const App = () => {
  const [show, setShow] = useState();
  return (
    <>
      {show && <Header />}
      <Routes>
        <Route path="/" element={<Login setShow={setShow} />} />
        <Route path="/admin/login" element={<AdminLogin setShow={setShow} />} />
        <Route path="/signup" element={<Signup setShow={setShow} />} />
        <Route
          path="/shipping"
          element={<VerifyShipiingBill setShow={setShow} />}
        />
        <Route path="/upload" element={<UploadDetail setShow={setShow} />} />
        <Route path="/settings" element={<Settings setShow={setShow} />} />
        <Route path="/create-bill" element={<CreateBill setShow={setShow} />} />
        <Route path="/allinvoice" element={<AllInvoice setShow={setShow} />} />
        <Route
          path="/shipping-bill-value"
          element={<ShippingBillExtractedTable setShow={setShow} />}
        />

        <Route
          path="/export-invoice"
          element={<ExportInvoiceGet setShow={setShow} />}
        />
        <Route
          path="/export-invoice-bill/*"
          element={<ExportInvoiceBillDownload setShow={setShow} />}
        />
      </Routes>
    </>
  );
};

export default App;
