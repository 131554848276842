import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Radio } from "react-bootstrap";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import Swal from "sweetalert2";
const InVoiceUpload = (prop) => {
  // console.log(prop);
  const [formData, setFormData] = useState({});
  const [signature, setSignature] = useState(null);
  const [selectedFileOne, setSelectedFileOne] = useState(null);
  const [selectedFileTwo, setSelectedFileTwo] = useState(null);
  const [selectedFileThree, setSelectedFileThree] = useState(null);
  const [selectedFileFour, setSelectedFileFour] = useState(null);
  const [selectedFileFive, setSelectedFileFive] = useState(null);
  const [uploadOrDownloadCount, setUploadOrDownloadCount] = React.useState(0);
  const [progress2, setProgressBar2] = useState(0);
  const [progress3, setProgressBar3] = useState(0);
  const [progress4, setProgressBar4] = useState(0);
  const [progress5, setProgressBar5] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [previewOne, setPreviewOne] = useState("");
  const [previewTwo, setPreviewTwo] = useState("");
  const [previewThree, setPreviewThree] = useState("");
  const [previewFour, setPreviewFour] = useState("");
  const [previewFive, setPreviewFive] = useState("");
  const userID = localStorage.getItem("userID");
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");
  const today = new Date().toISOString().slice(0, 10); // Get today's date (YYYY-MM-DD format)
  const whatsappGroupId = localStorage.getItem("whatsappGroupId");
  const [convertedFileUrl, setConvertedFileUrl] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/signature/${userID}`,
          {
            headers: {
              token: process.env.REACT_APP_TOKEN,
            },
          }
        );

        setSignature(response.data.data.signature);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (formData.bill_type == undefined) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        bill_type: "lut",
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleClose = () => {
    setFormData({});
    prop.setEditableData({});
    prop.setShowUpload(false);
    setSelectedFileOne(null);
    setSelectedFileTwo(null);
    setSelectedFileThree(null);
    setSelectedFileFour(null);
    setSelectedFileFive(null);
  };

  const [companyName, setCompanyName] = useState("");
  const [AdminPanelIdReal, getAdminPanelIdReal] = useState();
  const [billingType, setBillingType] = useState("");
  const getRegdata = () => {
    let da = "";
    let con = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/getReg/${userID}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
      },
      data: da,
    };
    axios
      .request(con)
      .then((res) => {
        res.data.data.map((value) => {
          setCompanyName(value.companyName);
          getAdminPanelIdReal(value.AdminPanelId);
          setBillingType(value.billingType);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRegdata();
  }, []);
  const [invoiceNumberError, setInvoiceNumberError] = useState("");
  const [invoiceDateError, setInvoiceDateError] = useState("");

  //starting here
  const uploadInvoice = (event) => {
    if (!formData.invoiceNTest) {
      setInvoiceNumberError("Please enter the invoice number.");
      return;
    }

    if (!formData.created_date) {
      formData.created_date = today;
    }

    event.preventDefault();
    if (selectedFileTwo === null) {
      var excelFile = "null";
      var excelStatus = "null";
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    let data = new FormData();
    data.append("userId", userID);
    data.append("invoiceNumber", formData.invoiceNTest);
    data.append("sent_to_cha", "no");
    data.append("invoice_pdf_status", "yes");
    data.append("invoice_excel_status", excelStatus);
    data.append("eway_bill_status", excelStatus);
    data.append("shipping_bill_recieved", "no");
    data.append("invoiceFile", selectedFileOne);
    data.append("eway_file", selectedFileFour);
    data.append("other_file", selectedFileFive);
    data.append("invoiceExcelFile", selectedFileTwo);
    data.append("invoicePdfFile", selectedFileThree);
    data.append("AdminPanelId", AdminPanelIdReal);
    data.append("created_date", formData.created_date);
    data.append("clientName", companyName);
    data.append("billType", formData.bill_type);
    data.append("vehicleNumber", formData.vehicleNumber);
    // data.append("whatsappGroupId", AdminPanelId);
    data.append("convertedPdf", convertedFileUrl);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://easy.multigrad.in/api/upload_invoice",
      headers: {
        token: process.env.REACT_APP_TOKEN,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "success") {
          prop.getInvoice();
          prop.setShowUpload(false);
          let data3 = JSON.stringify({
            to_number: prop.whatsappGroupId,
            type: "text",
            message: response.data.data.shippingBillFile,
          });
          let config3 = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://api.maytapi.com/api/6a4fdb19-272f-48d8-9792-783b32ee3beb/30170/sendMessage",
            headers: {
              "Content-Type": "application/json",
              "x-maytapi-key": "8753b0a9-761a-4b16-8f17-dec1994ba58b",
              token:
                "20b36f0f18fe59b51041f1c8e049007242fb40e53e25d093f5b652f9039f527b",
            },
            data: data3,
          };
          axios
            .request(config3)
            .then((respo) => {
              console.log(JSON.stringify(respo.data));
            })
            .catch((er) => {
              console.log(er);
            });

          console.log(selectedFileOne);
          let data4 = new FormData();
          data.append("file", selectedFileOne);
          let config2 = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://python.easyexport.in/api/easy_bill_ocr",
            headers: {
              "Content-Type": "application/json",
              "x-maytapi-key": "8753b0a9-761a-4b16-8f17-dec1994ba58b",
              token:
                "20b36f0f18fe59b51041f1c8e049007242fb40e53e25d093f5b652f9039f527b",
            },
            data: data4,
          };

          axios
            .request(config2)
            .then((resu) => {
              console.log(resu.data);
              // console.log(JSON.stringify(response.data));
            })
            .catch((erro) => {
              console.log(erro);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //ending here

  const editInvoice = (e) => {
    e.preventDefault();

    let data = new FormData();
    data.append("userId", userID);
    data.append("invoiceNumber", formData.invoiceNTest);
    data.append("sent_to_cha", "no");
    data.append("invoice_pdf_status", "yes");
    data.append("shipping_bill_recieved", "no");
    data.append("invoiceFile", selectedFileOne);
    data.append("eway_file", selectedFileFour);
    data.append("other_file", selectedFileFive);
    data.append("invoiceExcelFile", selectedFileTwo);
    data.append("invoicePdfFile", selectedFileThree);
    data.append("created_date", formData.created_date);
    // data.append("bill_type", formData.bill_type);
    data.append("billType", formData.bill_type);
    data.append("vehicleNumber", formData.vehicleNumber);
    data.append("convertedPdf", convertedFileUrl);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/updateInvoice/${prop.editableData._id}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.__v === 0) {
          prop.getInvoice();
          prop.setShowUpload(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (prop.editableData._id != null) {
      setFormData({
        ...formData,
        invoiceNTest: prop.editableData.invoiceNumber,
        created_date: moment
          .utc(prop.editableData.created_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD"),
        invoiceFile: prop.editableData.invoiceFile,
        eway_file: prop.editableData.eway_file,
        invoiceExcelFile: prop.editableData.invoiceExcelFile,
        invoicePdfFile: prop.editableData.invoicePdfFile,
        other_file: prop.editableData.other_file,
        vehicleNumber: prop.editableData.vehicleNumber,
      });
      setSelectedFileOne(prop.editableData.invoiceFile);
      setSelectedFileTwo(prop.editableData.invoiceExcelFile);
      setSelectedFileThree(prop.editableData.invoicePdfFile);
      setSelectedFileFour(prop.editableData.eway_file);
      setSelectedFileFive(prop.editableData.other_file);
    } else {
      setFormData({});
    }
  }, [prop]);

  const handleFileChangeOne = (event) => {
    setSelectedFileOne(event.target.files[0]);
    setPreviewOne(event.target.files[0].name);
    setUploadOrDownloadCount(100);

    console.log(selectedFileOne);

    let data4 = new FormData();
    data4.append("file", event.target.files[0]);

    let pythonUrl = "";
    if (billingType == "Easy Bill") {
      pythonUrl = "easy_bill_ocr";
    } else if (billingType == "Busy Bill") {
      pythonUrl = "busy_ocr";
    } else {
      pythonUrl = "tally_bill_ocr";
    }
    let config4 = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://python.easyexport.in/api/${pythonUrl}`,
      data: data4,
    };
    axios
      .request(config4)
      .then((resi) => {
        console.log(JSON.stringify(resi.data));
        if (resi.data.error === false) {
          setConvertedFileUrl(resi.data.file_link);
        } else {
          Swal.fire({
            icon: "error",
            title: `HSN Code Error! Item Number ${resi.data.list_of_id}`,
            showConfirmButton: false,
            timer: 10000,
          });
        }

        console.log(resi.data.error);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileChangeTwo = (event) => {
    setSelectedFileTwo(event.target.files[0]);
    setProgressBar2(100);
    setPreviewTwo(event.target.files[0].name);
  };

  const handleFileChangeThree = (event) => {
    setSelectedFileThree(event.target.files[0]);
    setPreviewThree(event.target.files[0].name);
    setProgressBar3(100);
  };

  const handleFileChangeFour = (event) => {
    setSelectedFileFour(event.target.files[0]);
    setPreviewFour(event.target.files[0].name);
    setProgressBar4(100);
  };

  const handleFileChangeFive = (event) => {
    setSelectedFileFive(event.target.files[0]);
    setPreviewFive(event.target.files[0].name);
    setProgressBar5(100);
  };

  const handleChangeDate = (e) => {
    setFormData({ ...formData, created_date: e.target.value });
    if (prop.editableData) {
      setDate(
        moment
          .utc(prop.editableData.created_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")
      );
    } else {
      setDate(e.target.value);
    }
  };
  return (
    <Modal show={prop.showUpload} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>EasyExport Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="customModal">
        <Form method="post" enctype="multipart/form-data">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Invoice Number </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="XYZ0011"
              name="invoiceNTest"
              value={formData.invoiceNTest}
              onChange={handleChange}
            />
          </Form.Group>
          {invoiceNumberError && (
            <p className="error-message">{invoiceNumberError}</p>
          )}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Invoice Date</Form.Label>
            <Form.Control
              required
              type="date"
              name="created_date"
              value={formData.created_date}
              defaultValue={today}
              onChange={handleChangeDate}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Vehicle number</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Vehicle Number"
              name="vehicleNumber"
              value={formData.vehicleNumber}
              onChange={handleChange}
            />
          </Form.Group>
          {invoiceDateError && (
            <p className="error-message">{invoiceDateError}</p>
          )}

          {/* <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Bill Type</Form.Label>
            <Form.Select
              onChange={handleChange}
              name="bill_type"
              id="bill_type"
            >
              <option value="">Select</option>
              <option value="igst">IGST</option>
              <option value="lut">LUT</option>
              <option value="na">NA</option>
            </Form.Select>
          </Form.Group> */}
          <Form.Label>Bill Type</Form.Label>
          <div
            style={{
              width: "20px",
              borderRadius: "50%",
              display: "flex",
              marginBottom: 20,
            }}
          >
            <div class="some-class">
              <input
                type="radio"
                className="radio"
                name="bill_type"
                value="lut"
                onChange={handleChange}
                checked
              />
              <label for="y">LUT</label>
              <input
                type="radio"
                className="radio"
                name="bill_type"
                value="igst"
                onChange={handleChange}
              />
              <label for="z">IGST</label>
              <input
                type="radio"
                className="radio"
                name="bill_type"
                value="na"
                onChange={handleChange}
              />
              <label for="z">NA</label>
            </div>
          </div>
          <Form.Group className="mb-3">
            <Form.Label>Invoice Pdf</Form.Label>
            <div style={{ display: "flex" }}>
              <Form.Control
                required
                autoFocus
                accept="application/pdf"
                name="formPdf"
                type="file"
                onChange={handleFileChangeOne}
              />
              {previewOne == "" ? (
                <p className="editable_data">{prop.editableData.invoiceFile}</p>
              ) : null}
              &nbsp;
              {selectedFileOne !== null && (
                <div style={{ width: "9%", height: "9%" }}>
                  <CircularProgressbar
                    value={uploadOrDownloadCount}
                    text={`${uploadOrDownloadCount}%`}
                  />
                </div>
              )}
            </div>
          </Form.Group>
          <p>{status}</p>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Invoice Excel</Form.Label>
            <div style={{ display: "flex" }}>
              <Form.Control
                autoFocus
                accept=".xls,.xlsx"
                type="file"
                onChange={handleFileChangeTwo}
              />
              {previewTwo == "" ? (
                <p className="editable_data">
                  {prop.editableData.invoiceExcelFile}
                </p>
              ) : null}
              &nbsp;
              {selectedFileTwo !== null && (
                <div style={{ width: "9%", height: "9%" }}>
                  <CircularProgressbar
                    value={progress2}
                    text={`${progress2}%`}
                  />
                </div>
              )}
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Declaration (SCOMAT)</Form.Label>
            <div style={{ display: "flex" }}>
              <Form.Control
                type="file"
                autoFocus
                accept="application/pdf,application/vnd.ms-excel"
                onChange={handleFileChangeThree}
              />
              {previewThree == "" ? (
                <p className="editable_data">
                  {prop.editableData.invoicePdfFile}
                </p>
              ) : null}
              &nbsp;
              {selectedFileThree !== null && (
                <div style={{ width: "9%", height: "9%" }}>
                  <CircularProgressbar
                    value={progress3}
                    text={`${progress3}%`}
                  />
                </div>
              )}
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Eway Bill</Form.Label>
            <div style={{ display: "flex" }}>
              <Form.Control
                type="file"
                autoFocus
                accept="application/pdf,application/vnd.ms-excel"
                onChange={handleFileChangeFour}
              />
              {previewFour == "" ? (
                <p className="editable_data">{prop.editableData.eway_file}</p>
              ) : null}
              &nbsp;
              {selectedFileFour !== null && (
                <div style={{ width: "9%", height: "9%" }}>
                  <CircularProgressbar
                    value={progress4}
                    text={`${progress4}%`}
                  />
                </div>
              )}
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Other Document</Form.Label>
            <div style={{ display: "flex" }}>
              <Form.Control
                type="file"
                autoFocus
                accept="application/pdf,application/vnd.ms-excel"
                onChange={handleFileChangeFive}
              />
              {previewFive == "" ? (
                <p className="editable_data">{prop.editableData.other_file}</p>
              ) : null}
              &nbsp;
              {selectedFileFive !== null && (
                <div style={{ width: "9%", height: "9%" }}>
                  <CircularProgressbar
                    value={progress5}
                    text={`${progress5}%`}
                  />
                </div>
              )}
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {prop.editableData._id == null ? (
          <Button variant="primary" onClick={uploadInvoice}>
            Upload Invoice
          </Button>
        ) : (
          <Button variant="primary" onClick={editInvoice}>
            Update Invoice
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default InVoiceUpload;
