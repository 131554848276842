import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FiUploadCloud } from "react-icons/fi";
import axios from "axios";

const UploadShippingBill = (prop) => {
  const [file, setFile] = useState("");
  const handleClose = () => {
    prop.setBillShow(false);
  };

  const upload = () => {
    let data = new FormData();
    data.append("userId", prop.billData._id);
    data.append("shippingBillFile", file);
    data.append("AdminPanelId", prop.billData.AdminPanelId);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/shipping-bill`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.message === "success") {
          prop.setBillShow(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal show={prop.billShow} onHide={handleClose} animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload Shipping Bill Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="file"
              autoFocus
              name="shippingBillFile"
              onChange={handleChange}
            />
          </Form.Group>

          <Button variant="secondary" onClick={upload}>
            <FiUploadCloud />
            &nbsp; Upload
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UploadShippingBill;
