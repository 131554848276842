import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const Completed = (prop) => {
  const [signFile, setSignFile] = useState();
  const [signature, setSignature] = useState();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    AdminPanelOffice: "",
    companyName: "",
    companyAddress: "",
    companyDistrict: "",
    companyState: "",
    companyNumber: "",
    panCard: "",
    gstNumber: "",
    iecNumber: "",
    adCode: "",
    lutNumber: "",
    bankName: "",
    bankNumber: "",
    bankAccountee: "",
    bankIfsc: "",
    branchName: "",
    AdminPanelId: "",
    autoSign: "",
    billingType: "",
  });
  const userID = localStorage.getItem("userID");
  const getRegData = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/getReg/${userID}`, {
        headers: {
          token: process.env.REACT_APP_TOKEN,
        },
      })
      .then((response) => {
        response.data.data.map((value) => {
          setFormData({
            email: value.email,
            AdminPanelOffice: value.AdminPanelOffice,
            companyName: value.companyName,
            companyAddress: value.companyAddress,
            companyDistrict: value.companyDistrict,
            companyState: value.companyState,
            companyNumber: value.companyNumber,
            panCard: value.panCard,
            gstNumber: value.gstNumber,
            iecNumber: value.iecNumber,
            adCode: value.adCode,
            lutNumber: value.lutNumber,
            bankName: value.bankName,
            bankNumber: value.bankNumber,
            bankAccountee: value.bankAccountee,
            bankIfsc: value.bankIfsc,
            branchName: value.branchName,
            AdminPanelId: value.AdminPanelId,
            autoSign: value.autoSign,
            billingType: value.billingType,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    prop.setShow(true);
  }, []);

  useEffect(() => {
    getRegData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = JSON.stringify({
      AdminPanelOffice: formData.AdminPanelOffice,
      companyAddress: formData.companyAddress,
      companyDistrict: formData.companyDistrict,
      companyState: formData.companyState,
      companyNumber: formData.companyNumber,
      panCard: formData.panCard,
      gstNumber: formData.gstNumber,
      iecNumber: formData.iecNumber,
      adCode: formData.adCode,
      lutNumber: formData.lutNumber,
      bankName: formData.bankName,
      bankNumber: formData.bankNumber,
      bankAccountee: formData.bankAccountee,
      bankIfsc: formData.bankIfsc,
      branchName: formData.branchName,
      fullName: formData.fullName,
      user_type: "user",
      AdminPanelId: formData.AdminPanelId,
      companyName: formData.companyName,
      autoSign: formData.autoSign,
      billingType: formData.billingType,
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL}/updateReg/${userID}`,
      headers: {
        token: process.env.REACT_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "success") {
          navigate("/allinvoice");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const signData = new FormData();
    signData.append("signature", signFile);
    signData.append("userId", userID);

    axios
      .post(`${process.env.REACT_APP_URL}/signature`, signData, {
        headers: {
          token: process.env.REACT_APP_TOKEN,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setSignFile(e.target.files[0]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/signature/${userID}`,
          {
            headers: {
              token: process.env.REACT_APP_TOKEN,
            },
          }
        );

        setSignature(response.data.data.signature);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="container">
        <div style={{ width: "70%", marginLeft: "15%", marginBottom: "30px" }}>
          <Card className="setting_card">
            <h5 style={{ textAlign: "center" }}>Company Details</h5>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  name="companyName"
                  onChange={handleChange}
                  value={formData.companyName}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  name="companyAddress"
                  onChange={handleChange}
                  value={formData.companyAddress}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>District</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your district"
                  name="companyDistrict"
                  onChange={handleChange}
                  value={formData.companyDistrict}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your state"
                  name="companyState"
                  onChange={handleChange}
                  value={formData.companyState}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your contact number"
                  name="companyNumber"
                  onChange={handleChange}
                  value={formData.companyNumber}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled
                />
              </Form.Group>

              <h5 style={{ textAlign: "center" }}>Registration Details</h5>
              <hr />
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>PAN</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your pan number"
                  name="panCard"
                  onChange={handleChange}
                  value={formData.panCard}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>GST</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your gst number"
                  name="gstNumber"
                  onChange={handleChange}
                  value={formData.gstNumber}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>IEC</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your iec number"
                  name="iecNumber"
                  onChange={handleChange}
                  value={formData.iecNumber}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>AD Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter AD Code"
                  name="adCode"
                  onChange={handleChange}
                  value={formData.adCode}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Lut</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter lut"
                  name="lutNumber"
                  onChange={handleChange}
                  value={formData.lutNumber}
                />
              </Form.Group>

              <h5 style={{ textAlign: "center" }}>Bank Details</h5>
              <hr />
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter bank name"
                  name="bankName"
                  onChange={handleChange}
                  value={formData.bankName}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Account Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter account name"
                  name="bankNumber"
                  onChange={handleChange}
                  value={formData.bankNumber}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter account number"
                  name="bankAccountee"
                  onChange={handleChange}
                  value={formData.bankAccountee}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>IFSC Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter IFSC Code"
                  name="bankIfsc"
                  onChange={handleChange}
                  value={formData.bankIfsc}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Branch Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter branch name"
                  name="branchName"
                  onChange={handleChange}
                  value={formData.branchName}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>CHA Office</Form.Label>
                {/* <Form.Control type="date" placeholder="Enter lut date" /> */}

                <Form.Select onChange={handleChange} name="AdminPanelId">
                  <option value={formData.AdminPanelId}>
                    {formData.AdminPanelId === 10001 && (
                      <>Sumukha freight forwarders</>
                    )}
                  </option>
                  <option value="10001">Sumukha freight forwarders</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Billing Type</Form.Label>
                {/* <Form.Control type="date" placeholder="Enter lut date" /> */}

                <Form.Select onChange={handleChange} name="billingType">
                  <option>{formData.billingType}</option>

                  <option>Easy Bill</option>
                  <option>Busy Bill</option>
                  <option>Tally Bill</option>
                  <option>Other Bill</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Auto Signature</Form.Label>
                <Form.Select onChange={handleChange} name="autoSign">
                  <option value={formData.autoSign}>
                    {formData.autoSign === "on" && <>On</>}
                    {formData.autoSign === "off" && <>Off</>}
                    {formData.autoSign === "" && <>Choose Auto Sign</>}
                  </option>
                  <option value="on">On</option>
                  <option value="off">Off</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <img
                  src={`https://easy.multigrad.in/media/?file=${signature}`}
                  width="200px"
                  height="200px"
                />
                <Form.Label>Signature (Only Png file)</Form.Label>
                <Form.Control
                  type="file"
                  autoFocus
                  name="shippingBillFile"
                  onChange={handleChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Completed;
